import { Action } from '@ngrx/store';
import { type } from '../../util';
import {UserSettings} from '../../models/user-settings';
export const ActionTypes = {
  SUBMITBILLINGINFOACTION: type('[Access] Submit billing info'),
  SUBMITBILLINGINFOSUCCESSACTION: type('[Access] Submit billing info success'),
  SUBMITBILLINGINFOFAILEDACTION: type('[Access] Submit billing info failure')
}

// Submit billing info success
export class SubmitBillingInfoSuccessAction implements Action {
  type = ActionTypes.SUBMITBILLINGINFOSUCCESSACTION;
  constructor(public payload: UserSettings) { }
}

// Submit billing info failed
export class SubmitBillingInfoFailedAction implements Action {
  type = ActionTypes.SUBMITBILLINGINFOFAILEDACTION;
  constructor(public payload: UserSettings) { }
}

export type Actions = SubmitBillingInfoSuccessAction | SubmitBillingInfoFailedAction;
