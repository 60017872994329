import dayjs from 'dayjs';
import { Injectable } from '@angular/core';
import {
  getGSettingsGlobalSettingsModel,
  State,
  getDashboardRole
} from '../redux/reducers';
import { UserSettings } from '../models/user-settings';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BillingStatus } from '../shared/enums';
import { SubmitBillingInfoFailedAction, SubmitBillingInfoSuccessAction } from '../redux/actions/access';
import { UserCompanyRole } from '@cartwheel/web-components';
import { take, switchMap } from 'rxjs/operators';
import { BillingData, BillingSelection } from 'app/models/billing';

@Injectable()
export class BillingService {

  instance: any;
  loading: boolean;
  getGlobalSettingsModel$: Observable<UserSettings>;
  getGlobalSettingsModel: UserSettings;
  private headers: HttpHeaders;
  private currentRole$: Observable<UserCompanyRole>;

  constructor(
    private http: HttpClient,
    private store: Store<State>
  ) {
    this.getGlobalSettingsModel$ = this.store.select(getGSettingsGlobalSettingsModel);
    this.currentRole$ = this.store.select(getDashboardRole);

    this.getGlobalSettingsModel$.subscribe((gModel: UserSettings) => {
      this.getGlobalSettingsModel = new UserSettings(gModel);
    });

    this.instance = window['Chargebee'].init({
      site: environment.chargeBee.site
    });
    this.headers = new HttpHeaders().append('Content-Type', 'application/json');
  }

  public openCheckout(planPayload: BillingSelection) {


    this.instance.openCheckout({
      hostedPage: () => {
        this.loading = true;

        const data = new BillingData();

        data.planInfo = planPayload;
        data.userSettings = this.getGlobalSettingsModel;

        return this.currentRole$.pipe(
          take(1),
          switchMap(role => {
            data.companyId = role.companyId;
            data.companyName = role.companyName;
            return this.http.post(`${environment.server}/Account/Subscribe?companyId=${role.companyId}`, data,
              { headers: this.headers });
          })
        ).toPromise();
      },
      loaded: () => {
        // Success
      },
      error: () => {
        this.loading = false;
        // this.ref.markForCheck();
        // this.errMsg = true;
      },
      close: () => {
        this.loading = false;
        // this.ref.detectChanges();
      },
      success: (hostedPageId) => {
        // Success Action?
        this.submitBillingInfo(planPayload).pipe(take(1)).subscribe((response: UserSettings) => {
          if (response.billingStatus === BillingStatus.Paid) {
            this.store.dispatch(new SubmitBillingInfoSuccessAction(response));
          } else {
            this.store.dispatch(new SubmitBillingInfoFailedAction(response));
          }
        })
      },
      step: (value) => {
        // value -> which step in checkout
        // console.log(value);
      }
    });
  }

  public submitEditBillingInfo(subscriptionId: String) {
    const cbPortal = this.instance.createChargebeePortal();

    const callbacks = {};
    cbPortal.open(callbacks,
      {
        sectionType: window['Chargebee'].getPortalSections().PAYMENT_SOURCES,
        params: {
          subscriptionId: subscriptionId
        }
      }
    );
  }

  public submitBillingInfo(planPayload: BillingSelection): Observable<UserSettings> {
    const userSettings = this.getGlobalSettingsModel;
    userSettings.billingCreationDate = dayjs().format();

    return this.currentRole$.pipe(
      take(1),
      switchMap(role => {
        const settings = new BillingData();
        settings.companyName = role.companyName;
        settings.companyId = role.companyId;
        settings.userSettings = userSettings;
        settings.planInfo = planPayload;
        return this.http.post<UserSettings>(
          `${environment.server}/Account/AddSubscription?companyId=${role.companyId}`, JSON.stringify(settings),
          { headers: this.headers });
      })
    )
  }

}
