import { Component, OnInit, EventEmitter, Inject, DestroyRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { State, getGSettingsPageAccessDisabled } from '../../../redux/reducers';
import { BillingSelection } from '../../../models/billing';
import { BillingService } from '../../../services/billing.service';

@Component({
  standalone: true,
  selector: 'app-reactivate-account',
  templateUrl: './reactivate-account.component.html',
  styleUrls: ['./reactivate-account.component.scss'],
  imports: [MatButtonToggleModule]
})

export class ReactivateAccountComponent implements OnInit {
  public chargeType = 'Yearly';
  public close = new EventEmitter<void>();
  public firstTimeSignUp = false;
  public totalUsersAmount = 0;
  public header = 'We are glad to have you back!';
  public subHeader = 'Please reactivate your account to start using Cartwheel again.';
  private planId = 'monthly';
  
  constructor(
    private billingService: BillingService,
    private store: Store<State>,
    private destroyRef: DestroyRef,
    @Inject(MAT_DIALOG_DATA) { header, subHeader }: { header: string, subHeader: string }
  ) {
    if (header) {
      this.header = header;
    }
    if (subHeader) {
      this.subHeader = subHeader;
    }
  }

  ngOnInit() {
    this.store.select(getGSettingsPageAccessDisabled).pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((pageDisabled) => {
        if (!pageDisabled) {
          this.close.emit();
        }
      });

    this.changeToggleValue(this.chargeType);
  }

  closeDialog() {
    //  this.store.dispatch(new HideCloseAccountDialogAction());
  }

  submitCloseAccount() {
    // this.store.dispatch(new SubmitCloseAccountAction());
  }

  redirectToPayment() {
    const payload = new BillingSelection();
    
    switch (this.chargeType.toLowerCase()) {
      case "yearly": {
        payload.planType = `oct-2023-${this.chargeType}-company`;
      }
      case "monthly": {
        payload.planType = `oct-2023-${this.chargeType}-company`;
      }
      default:
        payload.planType = `oct-2023-yearly-company`;
    }

    payload.planId = payload.planType;
    payload.planUsers = 1;
    this.billingService.openCheckout(payload);
  }

  handleChargeAmount(): number {
    switch (this.planId) {
      case 'monthly': {
        return 4.99;
      }

      case 'yearly': {
        return 4.99 * 12;
      }
    }
  }
  
  changeToggleValue(evt: String) {
    const currentPlan = evt.toLowerCase();
    
    switch (currentPlan) {
      case 'monthly': {
        this.chargeType = 'Monthly';
        this.planId = 'monthly';
        this.totalUsersAmount = this.handleChargeAmount();
        break;
      }
      case 'yearly': {
        this.chargeType = 'Annually';
        this.planId = 'yearly';
        this.totalUsersAmount = this.handleChargeAmount();
      }
    }
  }

}
