import { UserSettings } from './user-settings';
import { UUID } from 'angular2-uuid';

export class BillingData {
  planInfo: BillingSelection;
  userSettings: UserSettings;
  companyId: UUID;
  companyName: String;

  constructor(data?: any) {
    if (data) {
      if (data.planInfo) {
        this.planInfo = data.planInfo;
      }
      if (data.userSettings) {
        this.userSettings = data.userSettings;
      }
    }
  }
}


export class BillingSelection {
  planId: string;
  planType: string;
  planUsers: number;
  addonType: string;

  BillingSelection(data?: any) {
    if (data) {
      if (data.planId) {
        this.planId = data.planId;
      }

      if (data.planType) {
        this.planType = data.planType;
      }

      if (data.planUsers) {
        this.planUsers = data.planUsers;
      }

      if (this.addonType) {
        this.addonType = data.addonType;
      }
    }
  }
}
