<div class="close-account-modal-container">

  <div class="close-account-heading-text">{{ header }}</div>
  <div class="close-account-heading-subtext">{{ subHeader }}</div>

  <div class="reactivate-action-container">

    <div class="charge-btn-label section-container">
      <div class="section-1 type-charge-section">
        Charged {{chargeType}}
      </div>
      <div class="section-2">
        <mat-button-toggle-group #group="matButtonToggleGroup" value="Yearly">
          <mat-button-toggle class="toggle-btn" value="Monthly" (click)="changeToggleValue(monthly.value)" #monthly>
            Monthly
          </mat-button-toggle>
          <mat-button-toggle class="toggle-btn" value="Yearly" (click)="changeToggleValue(yearly.value)" #yearly>
            Annually
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <div class="total-bill-container">
      <div class="section-1">
        Total
      </div>
      <div class="section-2">
        $ {{totalUsersAmount.toFixed(2)}}
      </div>
    </div>

    <div class="buttonSections sections">
      <button class="mat-button mat-js-button mat-button--raised mat-js-ripple-effect mat-button--accent reactivate-btn"
        (click)="redirectToPayment()">
        Reactivate account
      </button>
    </div>

  </div>

</div>